<template>
    <div>
        <div class="client-profile-basic-details-wrapper">
            <div class="client-profile-basic-details">
                <div class="client-profile-basic-details-section-1">
                    <a-avatar shape="square" :size="100">
                        <template #icon><UserOutlined /></template>
                    </a-avatar>
                </div>
                <div class="client-profile-basic-details-section-2">
                    <div>
                        <span>Client Person Name</span>
                        <span class="spacer client-profile-basic-details-2-active-tag">Active</span>
                        <span>DOB</span>
                    </div>

                    <div style="margin-top: 7px;">
                        <span style="font-size: 12px;">Company Name</span>
                    </div>

                    <div style="margin-top: 7px;">
                        <span style="font-size: 12px;">Company ID</span>
                        <div style="margin-top: 10px">
                            <a-tag class="client-profile-basic-details-2-tags">Sector</a-tag>
                            <a-tag class="client-profile-basic-details-2-tags">Industry</a-tag>
                            <a-tag class="client-profile-basic-details-2-tags">Vertical</a-tag>
                            <a-tag class="client-profile-basic-details-2-tags">Segment</a-tag>
                        </div>
                    </div>

                    <div style="margin-top: 10px;display: flex;">
                        <div class="spacer-right">
                            <span style="display: block;font-size: 10px;">Create Date</span>
                            <span style="color: rgba(0, 0, 0, 0.76);font-size: 10px;">01/09/21</span>
                        </div>
                        <div class="spacer-right">
                            <span style="display: block;font-size: 10px;">Designation</span>
                            <span style="color: rgba(0, 0, 0, 0.76);font-size: 10px;">Designation</span>
                        </div>
                        <div class="spacer-right">
                            <span style="display: block;font-size: 10px;">Team</span>
                            <span style="color: rgba(0, 0, 0, 0.76);font-size: 10px;">Team</span>
                        </div>
                        <div>
                            <span style="display: block;font-size: 10px;">Vacancies Handled</span>
                            <span style="color: rgba(0, 0, 0, 0.76);font-size: 10px;">Vacancies Handled</span>
                        </div>
                    </div>
                </div>
                <div class="client-profile-basic-details-section-3">
                    <a-card class="client-profile-basic-details-section-3-card-1">
                        <span class="client-profile-basic-details-section-3-card-number">5</span>
                        <span class="client-profile-basic-details-section-3-card-text">Interviews</span>
                    </a-card>

                    <a-card class="client-profile-basic-details-section-3-card-2">
                        <span class="client-profile-basic-details-section-3-card-number">8</span>
                        <span class="client-profile-basic-details-section-3-card-text">Jobs Offered</span>
                    </a-card>

                    <a-card class="client-profile-basic-details-section-3-card-3">
                        <span class="client-profile-basic-details-section-3-card-number" style="color: #EB5757;">2</span>
                        <span class="client-profile-basic-details-section-3-card-text">Rejected</span>
                    </a-card>
                </div>

                <div class="client-profile-basic-details-section-4">
                    <EditOutlined />
                    <DeleteOutlined style="margin-left: 15px;" />
                </div>
            </div>

            <div class="client-profile-personal-professional-info">
                <a-tabs>
                    <a-tab-pane key="1" tab="Notes">
                        <a-table
                          :columns="columns"
                          :data-source="dataSource"
                        >
                        </a-table>
                    </a-tab-pane>
                </a-tabs>
            </div>
        </div>

        <div style="margin-top: 25px;" class="client-profile-basic-details-wrapper">
            <div class="client-profile-personal-professional-info">
                <a-tabs v-model:activeKey="activeKey">
                    <a-tab-pane class="tab-pane-1" key="1" tab="Meeting History">
                        <a-table
                          :columns="columns2"
                          :data-source="dataSource2"
                        >
                        </a-table>
                    </a-tab-pane>

                    <a-tab-pane class="tab-pane-1" key="2" tab="Vacancy History">
                        <a-table
                          :columns="columns3"
                          :data-source="dataSource3"
                        >
                        </a-table>
                    </a-tab-pane>
                </a-tabs>
            </div>
        </div>
    </div>
</template>

<script>
import { UserOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons-vue';
import { ref } from 'vue';

const columns = [
  {
    title: 'SR#',
    dataIndex: 'sr',
    width: 75
  },
  {
    title: 'Note',
    dataIndex: 'note',
    ellipsis: true,
    width: 700
  },
  {
    title: 'Date',
    dataIndex: 'date',
    width: 125
  },
  {
    title: 'Procedure Date',
    dataIndex: 'procedureDate',
    width: 125
  }
];

const columns1 = [
  {
    title: 'SR#',
    dataIndex: 'sr',
    width: 50
  },
  {
    title: 'File Name',
    dataIndex: 'fileName',
    ellipsis: true,
    width: 150
  },
  {
    title: 'File Size',
    dataIndex: 'fileSize',
    ellipsis: true,
    width: 75
  },
  {
    title: 'Doc Type',
    dataIndex: 'docType',
    ellipsis: true,
    width: 75
  },
  {
    title: 'Description',
    dataIndex: 'description',
    ellipsis: true,
    width: 200
  },
  {
    title: 'Date',
    dataIndex: 'date',
    width: 75
  }
];

const columns2 = [
  {
    title: 'SR#',
    dataIndex: 'sr',
    width: 50
  },
  {
    title: 'Meeting Type',
    dataIndex: 'meetingType',
    ellipsis: true,
    width: 150
  },
  {
    title: 'Meeting By',
    dataIndex: 'meetingBy',
    ellipsis: true,
    width: 75
  },
  {
    title: 'Date',
    dataIndex: 'date',
    width: 75      
  },
  {
    title: 'Team',
    dataIndex: 'team',
    ellipsis: true,
    width: 75
  },
  {
    title: 'Sub Team',
    dataIndex: 'subTeam',
    ellipsis: true,
    width: 200
  },
  {
    title: 'Level',
    dataIndex: 'leve;',
    width: 75
  },
  {
    title: 'Notes',
    dataIndex: 'notes',
    width: 75
  }
];

const columns3 = [
  {
    title: 'SR#',
    dataIndex: 'sr',
    width: 50
  },
  {
    title: 'Vacancy ID',
    dataIndex: 'vacancyId',
    ellipsis: true,
    width: 150
  },
  {
    title: 'Client',
    dataIndex: 'client',
    ellipsis: true,
    width: 100
  },
  {
    title: 'Vacancy Title',
    dataIndex: 'vacancyTitle',
    width: 100     
  },
  {
    title: 'Last Procedure',
    dataIndex: 'lastProcedure',
    ellipsis: true,
    width: 100
  },
  {
    title: 'Procedure Date',
    dataIndex: 'procedureDate',
    ellipsis: true,
    width: 100
  },
];

const dataSource = [];
const dataSource1 = [];
const dataSource2 = [];
const dataSource3 = [];

export default {
    components: {
        UserOutlined, EditOutlined, DeleteOutlined,
    },
    data() {
        return {
            activeKey: ref('1'),
            columns,
            columns1,
            columns2,
            columns3,
            dataSource,
            dataSource1,
            dataSource2,
            dataSource3
        }
    }
}
</script>

<style lang="scss" scoped>
@import "@/features/client/styles/clientProfile.scss";
</style>